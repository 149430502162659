<template>
    <div> 
        <b-card>
            <b-card-header>
                <div>
                <h4 v-if="isGrievance"> Welcome to Employee Grievance Page</h4> 
                <h4 v-else-if="isSuggestion"> Welcome to Employee Suggestion Page</h4>  
                <h4 v-else> Welcome to Employee Communication Page</h4> 
                <br><br>
                
                    <!-- <p class="text-danger">Please Note this Page is us under progress!!! Do not use until further update!!!</p> -->
                    <p v-if="mailSendStatus">
                        <b>
                            <!-- <span v-if="mailSendStatus === 'Success'" class="text-success"> Mail Sent Successfully !!!</span> -->
                            <span v-if="mailSendStatus === 'Failed'" class="text-danger"> Mail Sent Failed !!!</span>
                            
                        </b>
                    </p>
                    <p v-if="noSubject">
                        <span class="text-danger"> Something went wrong, Please try again after refreshing the page.</span>
                    </p>
                    
                    <div v-if="isGrievance">
                        <p> At Sattva Human, we are committed to fostering an inclusive, safe, and open work culture. We believe in providing a platform for our employees to voice their concerns, ensuring that everyone's perspective is valued and heard. Together, we strive to create a workplace where collaboration, respect, and transparency flourish, promoting the well-being and success of every team member. Accordingly, we strongly aim at building an environment where everyone can feel comfortable and courageous to talk about their problem individually amongst each other and grow together. </p>
                        <p> But you feel you need support from the leaders to address any of those concerns then we encourage you to kindly state your concern in detail in the text box below. We would like to urge you to enter your concerns with utmost clarity and, if possible, with specifics to ensure that it is understood in the exact manner you want.</p>
                        <p class="text-danger"> * We leave the choice of whether or not you would want to disclose your identification. However, if you think your concern is extremely serious then we would like to recommend you to mention your name. </p>
                        <p class="text-danger"> * Any action on the grievances mentioned here would be only taken with mutual agreements and confidentiality and we expect the same from you.</p>
                    </div>
                    <div v-else-if="isSuggestion">
                        <h5 class="text-primary">Enter the Suggestion you want to send to the Management.</h5>
                    </div>
                    <div v-else>
                        <!-- <b-button style="margin-right: 20px;" variant="outline-primary" @click="sendSuggestion">Send Suggestion</b-button>
                        <b-button variant="outline-warning" @click="sendGrievance">Send Grievance</b-button>
                        <p v-if="isGrievance"> You Selected Grievance </p>
                        <p v-if="isSuggestion"> You Selected Suggestion </p> -->
                        <b-form>
                            <h5>What would you like to send today?</h5>
          <!-- <b-form-group label="Choose the Status" label-for="vue-select" v-if="jobBySourcer === 'sourcer'"> -->
                            <b-form-group>
                                <li 
                                style="display: inline-flex; margin: 5px; padding: 2px"
                                >
                                <b-badge
                                    style="color: black !important"
                                    variant="light"
                                    id="suggestion"
                                    @click="selectChoice('suggestion')"
                                    >Suggestion</b-badge
                                >
                                </li>
                                <li 
                                style="display: inline-flex; margin: 5px; padding: 2px"
                                >
                                <b-badge
                                    style="color: black !important"
                                    variant="light"
                                    id="grievance"
                                    @click="selectChoice('grievance')"
                                    >Grievance</b-badge
                                >
                                </li>
                                </b-form-group>
                            <b-button variant="outline-primary" @click="checkChoice"> Submit</b-button>
                        </b-form>
                    </div>
                </div>
                
            </b-card-header>
            <b-form>
                <!-- <b-form-textarea
                :model="grievanceText"
                placeholder="Add your text here">

                </b-form-textarea> -->
                <b-form-group>
                    <quill-editor
                        v-model="mailBody"
                        :options="snowOption"
                        style="height: 200px"
                    />
                </b-form-group>
                <div>
                    <b-button variant="outline-primary" class="mt-5" @click="showModal()">Send</b-button>
                </div>
            </b-form>
        </b-card>
        
        <!-- modal backdrop -->
        <b-modal
            id="modal-choice"
            ref="modal-choice"
            
            ok-only
            no-close-on-backdrop
            title="What Would you like to send Today?"
            ok-title="Submit"
            @ok="checkChoice"
            
        >
            <b-card-text>
                <p> Please select one .. </p>
                <b-button style="margin-right: 20px;" variant="outline-primary" @click="sendSuggestion">Send Suggestion</b-button>
                <b-button variant="outline-warning" @click="sendGrievance">Send Grievance</b-button>
                <p v-if="isGrievance"> You Selected Grievance </p>
                <p v-if="isSuggestion"> You Selected Suggestion </p>
            </b-card-text>
            
        </b-modal>
        <b-modal
            id="modal-no-backdrop"
            ref="modal-no-backdrop"
            ok-only
            no-close-on-backdrop
            title="Disclaimer !!! Please read"
            ok-title="Accept"
            @ok="markAccepted"
        >
            <b-card-text>
                <p> At Sattva Human, we are committed to fostering an inclusive, safe, and open work culture. We believe in providing a platform for our employees to voice their concerns, ensuring that everyone's perspective is valued and heard. Together, we strive to create a workplace where collaboration, respect, and transparency flourish, promoting the well-being and success of every team member. Accordingly, we strongly aim at building an environment where everyone can feel comfortable and courageous to talk about their problem individually amongst each other and grow together. </p>
                <p> But you feel you need support from the leaders to address any of those concerns then we encourage you to kindly state your concern in detail in the text box below. We would like to urge you to enter your concerns with utmost clarity and, if possible, with specifics to ensure that it is understood in the exact manner you want.</p>
                <p class="text-danger"> * We leave the choice of whether or not you would want to disclose your identification. However, if you think your concern is extremely serious then we would like to recommend you to mention your name. </p>
                <p class="text-danger"> * Any action on the grievances mentioned here would be only taken with mutual agreements and confidentiality and we expect the same from you.</p>
            </b-card-text>
            
        </b-modal>

        <b-modal
            id="modal-before-send"
            ref="modal-before-send"
            
            no-close-on-backdrop
            title="Would you like to Add Your Name?"
            ok-title="Send With Name"
            cancel-title="Send Without Name"
            @ok="addName"
            @hide="handleCancel"
        >
            <b-card-text>
                <p v-if="isGrievance" class="text-danger"> * We leave the choice of whether or not you would want to disclose your identification. However, if you think your concern is extremely serious then we would like to recommend you to mention your name. </p>
                <p v-if="isGrievance" class="text-danger"> * Any action on the grievances mentioned here would be only taken with mutual agreements and confidentiality and we expect the same from you.</p>
                

                <b-form-input
                      id="name"
                      v-model="name"
                      placeholder="Add Full Name"
                      trim
                />
                <small class="text-danger" v-if="!name && sendClickedWithName">
                  Please add Your Name
                </small>                
                </b-card-text>
            
            
        </b-modal>
    </div>
</template>
<script>
import { BRow, BCol, BCard, BForm,BCardHeader, BButton, VBModal,BFormInput,BCardText,BFormGroup,BBadge, } from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import Ripple from "vue-ripple-directive";
import mailService from "@/services/mail-service"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
      BRow,
      BCol,
      BCard,
      BForm,
      BFormGroup,
      BBadge,
      //BFormTextarea,
      BCardHeader,
      BButton,
      quillEditor,
      ToastificationContent,
      BFormInput,
      BCardText
    },
    data() {
        return {
            snowOption: {
                theme: 'snow',
            },
            mailBody: null,
            accepted: false,
            mailData: {},
            name: '',
            sendClickedWithName: false,
            mailSendStatus: null,
            isSuggestion: false,
            isGrievance: false,
            noSubject: false,
            choice: null
        }
    },
    directives: {
    'b-modal': VBModal,
    Ripple,
  },
    created() {
        this.isLoggedIn()
        
    },
    methods: {
        sendMail(){
            if(this.isLoggedIn) {
            this.mailData = {
                // to: 'tarun.malhotra@sattvahuman.com; dhanya@sattvahuman.com',
                //to: 'ritesh@sattvahuman.com',
                to: 'sattva-leads@sattvahuman.com',
                cc: '',
                bcc: '',
                subject: this.isGrievance ? 'Employee Grievance Mail' : this.isSuggestion ? 'Employee Suggestion Mail' : null,
                html: this.mailBody,
                filename: '',
                cv_filename: '',
                id: JSON.parse(localStorage.getItem('userData')).id
            }
            if(!this.mailData.subject) {
                this.noSubject = true
            }
            mailService.gmail(this.mailData).then(res => {
                //console.log('sent')
                if(res.status === 'OK') {
                    this.mailSendStatus = 'Success'
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Email Sent",
                            icon: "MailIcon",
                            variant: "success",
                        },
                    });
                    this.mailBody = null 
                    this.name = null,
                    this.accepted = false
                    this.sendClickedWithName = false
                    this.isGrievance = false
                    this.isSuggestion = false
                    //this.mailSendStatus = null
                } else {
                    this.mailSendStatus = 'Failed'
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Email Failed! Please contact Admin",
                            icon: "MailIcon",
                            variant: "danger",
                        },
                    });
                }
                
            })
            }
            
        },
        isLoggedIn() {
            const expireIn = localStorage.getItem('expireIn')
            if (Math.floor(Date.now() / 1000 > expireIn)) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                //////console.log("called logout")
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
                return false
            }
            return true
        },
        markAccepted() {
            //console.log('accepted true')
            this.accepted = true
        },
        addName(bvModalEvt) {
            if(this.name) {
                this.mailBody = '<p><b>Employee Name: ' + this.name + '</b></p> ' + this.mailBody
                //console.log(this.mailBody)
                this.sendMail()
                this.sendClickedWithName = false;
            } else {
                if (!this.name || this.name === '') {
                    this.sendClickedWithName = true;
                     bvModalEvt.preventDefault(); // Prevents the modal from closing
                }
            }
        },
        noName() {
            //console.log('close called')
        },
        handleCancel(bvModalEvt) {
            // Check if the event is 'hide' and the trigger is 'cancel' button
            if (bvModalEvt.trigger === 'cancel') {
                //console.log("Cancel button clicked!");
                // Your custom logic here
                this.sendMail();
            }
        },
        checkChoice() {
            // if(isSuggestion) {
            //     this.sendMail()
            // } else 
            
            if(this.choice) {
                if(this.choice === 'suggestion') {
                    this.isSuggestion = true
                
                } else if(this.choice === 'grievance') {
                    this.isGrievance = true
                    this.$refs["modal-no-backdrop"].show();
                }
            } else {
                if((!this.isGrievance && !this.isSuggestion) || !this.choice) {
                    alert('Please Select One')
                }

                if(this.isGrievance) {
                    //this.showModal()
                    this.$refs["modal-no-backdrop"].show();
                }
            }
        },
        showModal() {
            this.$refs["modal-before-send"].show();
        },
        sendSuggestion() {
            this.isSuggestion = !this.isSuggestion
            this.isGrievance = false
            this.mailSendStatus = null
        },
        sendGrievance() {
            this.isGrievance = !this.isGrievance
            this.isSuggestion = false
            this.mailSendStatus = null
        },
        selectChoice(choice) {
            this.choice = choice
            if(choice === 'suggestion') {
                document.getElementById('suggestion').classList.remove('badge-light')
                document.getElementById('suggestion').classList.add('badge-primary')
                document.getElementById('grievance').classList.remove('badge-primary')
                document.getElementById('grievance').classList.add('badge-light')
                
            } else if(choice === 'grievance') {
                document.getElementById('grievance').classList.remove('badge-light')
                document.getElementById('grievance').classList.add('badge-primary')
                document.getElementById('suggestion').classList.remove('badge-primary')
                document.getElementById('suggestion').classList.add('badge-light')
                
            }
            
            
        }

     },
    mounted() {
        this.$refs["modal-choice"].show();  
    }    
}  

</script>